import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Assuming you're using react-icons

function Register() {

   const [showPassword, setShowPassword] = useState(false);

   const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};


    return (
        <div className='App'>
            <div className='custom-login '>
                <div className='container'>
                    <div className='m-2'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-5'>
                                        <div className='custom-inside-log-in'>
                                            <h2>Rempic</h2>
                                            <h6 className='fw-bold'>Sign In to Application</h6>
                                            <div className='custom-form'>
                                                <form>
                                                    <div class="mb-3">
                                                        <label for="exampleInputEmail1" class="form-label">Email address</label>
                                                        <input type="email" class="form-control input-form" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                    </div>
                                                    <div class="mb-3  position-relative">
                                                        <label for="exampleInputPassword1" class="form-label">Password</label>
                                                        <input  type={showPassword ? "text" : "password"} class="form-control input-form" id="exampleInputPassword1" />
                                                        <span
                                                            className="position-absolute top-50 end-0 translate-middle-y me-3 mt-3 cursor-pointer"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </div>
                                                    <div class="mb-3 form-check">
                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                        <label class="form-check-label" for="exampleCheck1">Remember Password</label>
                                                    </div>
                                                    <div className='w-100'>
                                                        <button type="submit" class=" w-40 custom-main-button">Submit</button>
                                                    </div>
                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <p><a href='forgot-password' className='custom-a-1'>Forgot Password</a></p>
                                                        <p>Login</p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import { MdDownloadForOffline } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Modal, Button, Spinner } from 'react-bootstrap';
import { RiDeleteBin6Line } from "react-icons/ri";

function Userlist() {

    const [userList, setUserList] = useState([])
    const [businessList, setBussinessList] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalBussPages, setTotalBussPages] = useState(0);
    const [rowsPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [type, setType] = useState("");
    const [isLoading, setisLoading] = useState(false)

    const Navigate = useNavigate()

    const getUserData = async (page = 1, search = '') => {
        try {
            setisLoading(true)
            let params = {
                url: `userDetails?page=${page}&limit=${rowsPerPage}&search=${search}`,
                method: "GET"
            }
            const response = await makeApiRequest(params)
            if (response.status === true) {
                const { data, totalPages, datas, totalPage } = response;
                setUserList(data)
                setBussinessList(datas)
                setTotalPages(totalPages);
                setTotalBussPages(totalPage);
                setisLoading(false)
            } else {
                setUserList([])
                setisLoading(false)
            }

        } catch (error) {
            toast.error("Something Wrong")
        }
    }

    useEffect(() => {
        getUserData(currentPage, searchQuery);
    }, [currentPage, searchQuery])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };


    const handleDeleteClick = (row) => {

        setSelectedRow(row);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            await UserListDelete(selectedRow);
        }
        setShowModal(false);
    };

    const handleClose = () => setShowModal(false);

    const UserListDelete = async (row) => {
        setisLoading(true)
        let datas = {
            userListID: row._id,
            type: type
        }

        let params = {
            url: `UserlistDelete`,
            method: 'POST',
            data: datas
        };
        await makeApiRequest(params)
            .then((response) => {
                toast.success(response.message);
                setisLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
            .catch((error) => {
                console.log('error', error);
                setisLoading(false)
            })
    };


    const columnsone = [
        {
            name: 'S.No',
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: true,
            width: "75px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "280px",
        },
        {
            name: 'First Name',
            selector: row => row.first_name,
            sortable: true,
            width: "120px",
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
            sortable: true,
            width: "120px",
        },
        {
            name: 'Referral Id',
            selector: row => row.referral_id ? row.referral_id : '--',
            sortable: true,
            width: "130px",
        },
        {
            name: 'KYC Data',
            cell: (row) => (
                <div>
                    {/* <a href="/individual"> */}
                    <button className="custom-nav-button-1 active" onClick={() => handleKYC(row)}> KYC Data</button>
                    {/* </a> */}
                </div>
            ),
            sortable: true,
            width: "130px",
        },
        {
            name: 'User Acccount Form',
            cell: (row) => (
                <div>
                    {/* <a href="/individual"> */}
                    <button className="custom-nav-button-1 active" onClick={() => handleIndivdualClick(row)}> Activation</button>
                    {/* </a> */}
                </div>
            ),
            sortable: true,
            width: "160px",
        },
        // {
        //     name: 'Business Form',
        //     cell: (row) => (
        //         <div>
        //             <button className="custom-nav-button-1 active" onClick={() => handleBusinessClick(row)}>Bussiness</button>
        //         </div>
        //     ),
        //     sortable: true,
        //     width: "140px",
        // },
        {
            name: 'User Status',
            cell: row => (
                <span style={{ color: row.account_status === "Active" ? "green" : "red" }}>
                    {row.account_status}
                </span>
            ),
            sortable: true,
            width: "120px",
        },
        {
            name: 'ACTION',
            cell: (row) => (
                // <div>
                //     <button className="custom-nav-button-1 active" 
                //     onClick={() => handleDeleteClick(row)}
                //     > <RiDeleteBin6Line /></button>
                // </div>
                <div>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === "1") {
                                handleDeleteClick(row);
                                setType("Deactive");
                            } else if (value === "2") {
                                handleDeleteClick(row);
                                setType("Delete");
                            }
                        }}
                    >
                        <option value="" selected>Select..</option>
                        <option value="1">Disable</option>
                        <option value="2">Delete</option>
                    </select>
                </div>

            ),
            sortable: true,
            width: "180px"
        },



    ];

    const bussColumns = [
        {
            name: 'S.No',
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Email',
            selector: row => row.user_email,
            sortable: true,
            width: "300px",
        },
        {
            name: 'First Name',
            selector: row => row.firstname,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Business Form',
            cell: (row) => (
                <div>
                    <button className="custom-nav-button-1 active" onClick={() => handleBusinessClick(row)}>Bussiness</button>
                </div>
            ),
            sortable: true,
            width: "250px",
        },
    ]

    const handleKYC = async (row) => {
        try {
            let params = {
                url: 'get_KYC_Form',
                method: "post",
                data: { id: row._id }
            }
            const response = await makeApiRequest(params);
            if (response.status === true) {
                const kycForm = response.resp;
                if (kycForm) {
                    Navigate("/kyc_details", { state: { kycForm: kycForm } })
                }
                else {
                    toast.error("This user has not yet uploaded their KYC.");
                }
            } else {
                toast.error("KYC form not found for this user.");
            }
        } catch (error) {
            toast.error("Error fetching KYC form.");
        }
    }

    const handleIndivdualClick = async (row) => {
        try {
            let params = {
                url: 'get_IndividualForm',
                method: "post",
                data: { id: row._id }
            }
            const response = await makeApiRequest(params);
            if (response.status === true) {
                const individualFormData = response.resp;
                if (individualFormData) {
                    Navigate("/individual", { state: { individualFormData: individualFormData } })
                }
                else {
                    toast.error("This user has not yet filled their individual onboarding form.");
                }
            } else {
                toast.error("Individual form not found for this user.");
            }
        } catch (error) {
            toast.error("Error fetching individual form.");
        }
    }

    const handleBusinessClick = async (row) => {
        try {
            let params = {
                url: 'get_BusinessForm',
                method: "post",
                data: { id: row.user_id }
            }
            const response = await makeApiRequest(params);
            console.log('response--', response)
            if (response.status === true) {
                const businessFormData = response.resp;
                if (businessFormData) {
                    Navigate("/business", { state: { businessFormData: businessFormData } })
                }
                else {
                    toast.error("User is Business form is not filled.");
                }
            } else {
                toast.error("Business form not found for this user.");
            }
        } catch (error) {
            toast.error("Error fetching business form.");
        }
    }

    const [isVisible, setIsVisible] = useState(true);

    const handleClose1 = () => {
        setIsVisible(false);
    };

    return (

        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm {type}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to {type} this User?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            {isLoading == true ? (
                                <Button >
                                    <Spinner animation="border" />
                                </Button>
                            ) : (
                                <Button variant="danger" onClick={confirmDelete}>
                                    {type}
                                </Button>
                            )}

                        </Modal.Footer>
                    </Modal>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>

                                <div className="custom-nav-userlist-1">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button
                                                class="nav-link active"
                                                id="pills-home-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-home"
                                                type="button" role="tab"
                                                aria-controls="pills-home"
                                                aria-selected="true"
                                            >
                                                User Account
                                            </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button
                                                class="nav-link"
                                                id="pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-profile"
                                                type="button" role="tab"
                                                aria-controls="pills-profile"
                                                aria-selected="false"
                                            >
                                                Bussiness Account
                                            </button>
                                        </li>

                                    </ul>

                                    <div className='mb-3'>
                                        <input
                                            type="text"
                                            placeholder="Search by email, first name, last name, referral ID"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="search-bar"
                                        />
                                    </div>


                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                            {isVisible == true ? (
                                                <div className="custom-heading-scroll d-flex justify-content-between" >
                                                    <p className="mb-0">The table below can be scrolled horizontally, if needed.</p>
                                                    <div onClick={handleClose1} style={{ cursor: 'pointer' }}>
                                                        <IoMdClose />
                                                    </div>
                                                </div>

                                            ) : ""}

                                            <div className='liquidity-table-1'>
                                                <DataTable
                                                    columns={columnsone}
                                                    data={userList}
                                                    theme="solarized"
                                                    defaultSortAsc={true}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalPages * rowsPerPage}
                                                    onChangePage={handlePageChange}
                                                    paginationPerPage={rowsPerPage}
                                                    highlightOnHover
                                                    dense
                                                    noDataComponent={
                                                        isLoading ? (
                                                            <div className="text-center py-2">
                                                              <Spinner animation="border" />
                                                            </div>
                                                          ) : (
                                                            <div className="text-center py-2">
                                                              <span>No data found</span>
                                                            </div>
                                                          )
                                                       }
                                                />
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                            <div className='liquidity-table-1'>
                                                <DataTable
                                                    columns={bussColumns}
                                                    data={businessList}
                                                    theme="solarized"
                                                    defaultSortAsc={true}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalBussPages * rowsPerPage}
                                                    onChangePage={handlePageChange}
                                                    paginationPerPage={rowsPerPage}
                                                    highlightOnHover
                                                    dense
                                                    noDataComponent={
                                                       isLoading ? (
                                                            <div className="text-center py-2">
                                                              <Spinner animation="border" />
                                                            </div>
                                                          ) : (
                                                            <div className="text-center py-2">
                                                              <span>No data found</span>
                                                            </div>
                                                          )
                                                       }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Userlist
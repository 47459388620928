import './App.css';
import Dashboard from './seperate/Dashboard';
import { BrowserRouter, Route, Routes , Navigate} from 'react-router-dom';
import Login from './seperate/Login';
import Forgotpassword from './seperate/Forgotpassword';
import Resetpassword from './seperate/Resetpassword';
import Register from './seperate/Register';
import ProtectedRoute from './Auth/ProtectRoute';
import Individual from './seperate/Individual';
import Business from './seperate/Business';
import KYCform from './seperate/KYCform';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
     
      <Routes>
        {/* Redirect from "/" to "/dashboard/analytics" */}
        {/* <Route path="/" element={<Navigate to="/dashboard/analytics" replace />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot-password" element={<Forgotpassword />} />
        <Route exact path="/reset-password" element={<Resetpassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path='/kyc_details' element={<KYCform />} />
        <Route exact path="/individual" element={<Individual />} />
        <Route exact path="/business" element={<Business />} />
        <Route path="/dashboard/analytics" element={<ProtectedRoute> <Dashboard />  </ProtectedRoute>} />
        <Route path="/dashboard/home" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
        <Route path="/dashboard/userDetail" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
        <Route path="/dashboard/user-list" element={<Dashboard />} />
      </Routes>
      
    </BrowserRouter>
    </div>
  );
}

export default App;
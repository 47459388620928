import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { MdDownloadForOffline } from "react-icons/md";

function Analytics() {

    const [userList, setUserList] = useState([])

    const getUserData = async () => {
        try {
            let params = {
                url: "userDetails",
                method: "GET"
            }
            const response = await makeApiRequest(params)
            if (response.status === true) {
                const userData = response.data;
                setUserList(userData)

            } else {
                setUserList([])
            }

        } catch (error) {
            toast.error("Something Wrong")
        }
    }

    useEffect(() => {
        getUserData();
    }, [])

    const columnsone = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'First Name',
            selector: row => row.first_name,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.last_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Referral Id',
            selector: row => row.referral_id ? row.referral_id : '--',
            sortable: true,
            width: "150px",
        },
        {
            name: 'Download',
            selector: row => row.download ? row.download :
                <div className=" fs-5">
                   <MdDownloadForOffline />
                </div>,
            sortable: true,
            width: "150px",
        },
    ];

    return (
        <div className='App '>
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='liquidity-table-1'>
                                    <DataTable
                                        columns={columnsone}
                                        data={userList}
                                        theme="solarized"
                                        defaultSortAsc={true}
                                        pagination
                                        highlightOnHover
                                        dense />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics
import React, { useState, useEffect } from "react";
import Dashboard from './Dashboard';
import DataTable from 'react-data-table-component';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Individual() {
    const [isVisible, setIsVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const location = useLocation();
    const Navigate = useNavigate();
    const { individualFormData } = location.state || [];

    const legalEntity = individualFormData?.legal_Entity || {};
    const Individual = individualFormData?.individuals || {};
    const UBO = individualFormData?.ubo || {}



    const columns = [
        {
            name: 'individuals',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },
        {
            name: '',
            selector: row => {
                if (typeof row.value === 'string' && (row.value.endsWith('.jpg') || row.value.endsWith('.png'))) {
                    return <img src={row.value} alt={row.field} style={{ width: '100px', height: 'auto' }} />;
                }
                return row.value;
            },
            sortable: false,
            width: '200px',
        },
    ];

    const columns1 = [

        {
            name: 'legal entity',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },

        {
            name: '',
            selector: row => row.value, // Access 'value' key in each row
            sortable: false,
            width: '200px',
        }

    ];



    const columns2 = [
        {
            name: 'UBO',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },
        {
            name: '',
            selector: row => {
                if (typeof row.value === 'string' && (row.value.endsWith('.jpg') || row.value.endsWith('.png'))) {
                    return <img src={row.value} alt={row.field} style={{ width: '100px', height: 'auto' }} />;
                }
                return row.value;
            },
            sortable: false,
            width: '200px',
        }
    ];

    const verticalData = [
        { id: `1`, field: ' Surname', value: Individual.surname || "--" },
        { id: `2`, field: 'Date of birth', value: Individual.dob || "--" },
        { id: `3`, field: 'Place of birth', value: Individual.birth_place || "--" },
        { id: `4`, field: 'Citizenship', value: Individual.citizenship || "--" },
        { id: `5`, field: 'Actual address', value: Individual.actual_address || "--" },
        { id: `6`, field: 'Domicile', value: Individual.domicile || "--" },
        { id: `7`, field: 'ID or Passport information' },
        { id: `8`, field: 'Name of the document', value: Individual?.passport_information?.document_name || "--" },
        { id: `9`, field: 'Number', value: Individual?.passport_information?.number || "--" },
        { id: `10`, field: 'Issuing body', value: Individual?.passport_information?.issuing_body || "--" },
        { id: `11`, field: 'Validity (date of issue and date of expiration)', value: Individual?.passport_information?.expiry_date || "--" },
        { id: `12`, field: 'Place of work, title', value: Individual?.passport_information?.work_place || "--" },
        { id: `13`, field: 'Share in the share capital of the Company (for shareholders only)', value: Individual?.passport_information?.share_capital || "--" },
        { id: `14`, field: 'Country of Tax Residency', value: Individual?.passport_information?.tax_residency || "--" },
        { id: `15`, field: 'Source of wealth', value: Individual?.passport_information?.source_wealth || "--" },
        { id: `16`, field: 'Are you  a public official/ Politically Exposed Person?', value: Individual?.passport_information?.public_officials_1?.officials_1 ? 'Yes' : 'No' },
        { id: `17`, field: 'If Yes, please specify:', value: Individual.passport_information.public_officials_1.specify_official_1 || "--" },
        { id: `18`, field: 'Is any of your close family members/associates public official/ Politically Exposed Person?', value: Individual?.passport_information?.public_officials_2?.officials_2 ? 'Yes' : 'No' },
        { id: `19`, field: 'If Yes, please specify:', value: Individual?.passport_information?.public_officials_2?.specify_official_2 || "--" },
        { id: `20`, field: 'Position held ', value: Individual?.passport_information?.public_officials_2?.position_held || "--" },
        { id: `21`, field: 'Period', value: Individual?.passport_information?.public_officials_2?.period || "--" },
        { id: `22`, field: 'Relationship with you', value: Individual.passport_information?.public_officials_2?.relationship || "--" },
        { id: `23`, field: 'Is the Shareholder acting as a nominee?', value: Individual?.passport_information?.shareHolder_nominee ? 'Yes' : 'No' },
        { id: `24`, field: 'Contact phone number or faximile', value: Individual?.passport_information?.phone_number || "--" },
        { id: `25`, field: 'Email', value: Individual?.passport_information?.email || "--" },
        { id: `26`, field: 'Date', value: Individual?.passport_information?.date || "--" },
        { id: `27`, field: 'Signature', value: Individual.passport_information.signature || "--" },

    ].flat();

    // Transform the data to vertical format
    const verticalData2 = [
        { id: `1`, field: 'Full name', value: legalEntity.full_name || "--" },
        { id: `2`, field: 'Abbreviate name', value: legalEntity.abbrevation_Name || "--" },
        { id: `3`, field: 'Foreign languages name (if applicable)', value: legalEntity.foreign_lang_name || "--" },
        { id: `5`, field: 'State registration information' },
        { id: `6`, field: 'Data', value: legalEntity.data || "--" },
        { id: `7`, field: 'Registration number', value: legalEntity.register_numb || "--" },
        { id: `8`, field: 'Name of the registration authority', value: legalEntity.register_authority || "--" },
        { id: `9`, field: 'Legal address', value: legalEntity.legal_address || "--" },
        { id: `10`, field: 'Postal address', value: legalEntity.postal_address || "--" },
        { id: `11`, field: 'E-mail, URL', value: legalEntity.email_or_url || "--" },
        { id: `13`, field: 'Country of Tax Residency', value: legalEntity.tax_residency || "--" },
        { id: `14`, field: 'TAX number', value: legalEntity.tax_number || "--" },
        { id: `15`, field: 'Percentage and number of shares in the share capital of the Company', value: legalEntity.shares || "--" },
        { id: `16`, field: 'Is the Shareholder acting as a nominee?', value: legalEntity.shareHolder ? 'Yes' : 'No' },
        { id: `17`, field: 'Date', value: legalEntity.date || "--" },
    ].flat();



    const verticalData3 = [
        { id: `1`, field: 'First Name', value: UBO.first_name || "--" },
        { id: `2`, field: 'Last name', value: UBO.last_name || "--" },
        { id: `3`, field: 'Patronymic (if applicable)', value: UBO.patronymic || "--" },
        { id: `4`, field: 'Date of birth', value: UBO.dob },
        { id: `5`, field: 'Place of birth', value: UBO.birth_place },
        { id: `6`, field: 'Citizenship', value: UBO.citizenship },
        { id: `7`, field: 'Share in the share capital of the Company', value: UBO.company_shareCapital },
        { id: `8`, field: 'Domicile', value: UBO.domicile },
        { id: `9`, field: 'Actual address', value: UBO.actual_address },
        { id: `10`, field: 'Source of wealth', value: UBO.source_wealth },
        { id: `11`, field: 'Country of Tax Residency', value: UBO.tax_residency },
        { id: `12`, field: 'Are you a public official/ Politically Exposed Person?', value: UBO?.public_officials_1?.officials_1 ? 'Yes' : 'No' },
        { id: `17`, field: 'If Yes, please specify:', value: UBO.public_officials_1.specify_official_1 || "--" },
        { id: `13`, field: 'Is any of your close family members/associates a public official/ Politically Exposed Person?', value: UBO?.public_officials_2?.officials_2 ? 'Yes' : 'No' },
        { id: `18`, field: 'If Yes, please specify:', value: UBO?.public_officials_2?.specify_official_2 || "--" },
        { id: `20`, field: 'Position held ', value: UBO?.public_officials_2?.position_held || "--" },
        { id: `21`, field: 'Period', value: UBO?.public_officials_2?.period || "--" },
        { id: `22`, field: 'Relationship with you', value: UBO?.public_officials_2?.relationship || "--" },
        { id: `14`, field: 'Contact phone number or faximile/e-mail', value: UBO.phone_number || "--" },
        { id: `15`, field: 'ID or Passport information' },
        { id: `16`, field: 'Name of the document', value: UBO?.passport_information?.document_name || "--" },
        { id: `17`, field: 'Number', value: UBO?.passport_information?.number || "--" },
        { id: `18`, field: 'Issuing body', value: UBO?.passport_information?.issuing_body || "--" },
        { id: `19`, field: 'Validity (date of issue and date of expiration)', value: UBO?.passport_information?.expiry_date || "--" },
        { id: `20`, field: 'Type of business activity', value: UBO?.passport_information?.business_activity || "--" },
        { id: `21`, field: 'Signature', value: UBO?.passport_information?.signature || "--" },
    ].flat();



    const handleDeleteClick = () => {
        setSelectedRow(individualFormData._id);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            await handleKYCDelete(selectedRow);
        }
        setShowModal(false);
    };

    const handleClose = () => setShowModal(false);

    const handleKYCDelete = async () => {
        let datas = {
            FormId: selectedRow,
            type: "IndividualDelete"
        }
        let params = {
            url: `formsDelete`,
            method: 'POST',
            data: datas,

        };
        console.log('params', params)
        await makeApiRequest(params)
            .then((response) => {
                console.log('response', response)
                toast.success(response.message);
                setTimeout(() => {
                    Navigate("/dashboard/user-list")
                }, 2000)
            })
            .catch((error) => {
                console.log('error', error);
            })
    };



    // Show button when the user scrolls down 300px
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the page to the top when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling effect
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);





    const downloadInvoice = () => {
        const doc = new jsPDF();
        // Individual Form
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text("", 105, 10, { align: "center" });

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(0, 102, 204);
        doc.text("Individuals Form", 14, 20);

        doc.autoTable({
            headStyles: {

                fillColor: [0, 102, 204],
                textColor: [255, 255, 255],
                fontStyle: 'bold',

            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [50, 50, 50],

            },
            alternateRowStyles: {
                fillColor: [255, 255, 255],  // Ensure alternate rows also have white background

            },
            columnStyles: {
                0: { cellWidth: 95 },
                1: { cellWidth: 95 },
            },
            head: [['Field', 'Value']],
            body: verticalData.map(item => {
                if (item.field === 'Signature' && typeof item.value === 'string' && (item.value.endsWith('.jpg') || item.value.endsWith('.png'))) {
                    return [item.field, ''];
                }
                return [item.field, item.value || "--"];
            }),
            startY: 25,
            didDrawCell: (data) => {
                if (data.column.index === 1 && data.row.raw[0] === 'Signature') {
                    const signatureValue = verticalData.find(item => item.field === 'Signature').value;
                    if (typeof signatureValue === 'string' && (signatureValue.endsWith('.jpg') || signatureValue.endsWith('.png'))) {
                        const imgWidth = 40;
                        const imgHeight = 20;

                        const imgX = data.cell.x + 2;
                        const imgY = data.cell.y + 2;

                        doc.addImage(signatureValue, 'JPEG', imgX, imgY, imgWidth, imgHeight);
                    }
                }
            }

        });

        doc.addPage();
        // Legal Entity Form
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(0, 102, 204);
        doc.text("Legal Entity Form", 14, 20);

        doc.autoTable({
            headStyles: {
                fillColor: [0, 102, 204],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [50, 50, 50],
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255],  // Ensure alternate rows also have white background
            },
            columnStyles: {
                0: { cellWidth: 95 },
                1: { cellWidth: 95 },
            },
            head: [['Field', 'Value']],
            body: verticalData2.map(item => [item.field, item.value || "--"]),
            startY: 25,
        });

        doc.addPage();
        // UBO Form 
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(0, 102, 204);
        doc.text("UBO Form", 14, 20);

        doc.autoTable({
            headStyles: {
                fillColor: [0, 102, 204],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
            },
            bodyStyles: {
                fillColor: [255, 255, 255],
                fontStyle: 'normal',
                fontSize: 10,
                textColor: [50, 50, 50],
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255],  // Ensure alternate rows also have white background
            },
            columnStyles: {
                0: { cellWidth: 95 },
                1: { cellWidth: 95 },
            },
            head: [['Field', 'Value']],
            body: verticalData3.map(item => {
                if (item.field === 'Signature' && typeof item.value === 'string' && (item.value.endsWith('.jpg') || item.value.endsWith('.png'))) {
                    return [item.field, ''];
                }
                return [item.field, item.value || "--"];
            }),
            startY: 25,
            didDrawCell: (data) => {
                if (data.column.index === 1 && data.row.raw[0] === 'Signature') {
                    const signatureValue = verticalData.find(item => item.field === 'Signature').value;
                    if (typeof signatureValue === 'string' && (signatureValue.endsWith('.jpg') || signatureValue.endsWith('.png'))) {
                        const imgWidth = 40;
                        const imgHeight = 20;

                        const imgX = data.cell.x + 2;
                        const imgY = data.cell.y + 2;

                        doc.addImage(signatureValue, 'JPEG', imgX, imgY, imgWidth, imgHeight);
                    }
                }
            }
        });

        doc.setFontSize(10);
        doc.setTextColor(100);
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(`Page ${i} of ${pageCount}`, 105, 290, { align: 'center' });
        }
        const userEmail = individualFormData.user_email.split("@")[0]
        doc.save(`${userEmail}-Individual-Form.pdf`);
    };



    return (
        <div className='App custom-individual'>
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this KYC Data?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <Dashboard />
                            </div>
                            <div className='col-lg-8 me-sm-auto mb-4 p-0'>
                                <div className="d-flex justify-content-between">
                                    <a href='/dashboard/user-list'>
                                        <p className="back-button m-0" style={{ color: "#1eb7ff" }}><FaRegArrowAltCircleLeft /> Back to User List</p>
                                    </a>



                                    <div className="d-flex">
                                        <div className="me-3">
                                            <button className="custom-nav-button-1 active" onClick={downloadInvoice}>
                                                Download PDF
                                            </button>
                                        </div>
                                        <button className="custom-nav-button-1 active" onClick={handleDeleteClick}>Delete <RiDeleteBin6Line /></button>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-8 m-sm-auto m-0 p-0'>

                                <div className='liquidity-table-1 first-table-ind'>
                                    <DataTable
                                        columns={columns}
                                        data={verticalData}
                                        noHeader
                                        highlightOnHover
                                        dense
                                    />
                                </div>

                                <div className='liquidity-table-1 mt-3 second-table-ind'>
                                    <DataTable
                                        columns={columns1}
                                        data={verticalData2}
                                        noHeader
                                        highlightOnHover
                                        dense

                                    />
                                </div>

                                <div className='liquidity-table-1 mt-3 third-table-ind'>
                                    <DataTable
                                        columns={columns2}
                                        data={verticalData3}
                                        noHeader
                                        highlightOnHover
                                        dense
                                    />
                                </div>



                            </div>
                        </div>
                    </div>
                    <div>
                        {isVisible && (
                            <button
                                onClick={scrollToTop}
                                style={{
                                    position: "fixed",
                                    bottom: "70px",
                                    right: "50px",
                                    padding: "5px 16px",
                                    fontSize: "25px",
                                    backgroundColor: "#1eb7ff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            >
                                ↑
                            </button>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Individual;

import React, { useState, useEffect } from "react";
import Dashboard from './Dashboard';
import DataTable from 'react-data-table-component';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'

function KYCform() {
    const [isVisible, setIsVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const location = useLocation();
    const { kycForm } = location.state || [];
    const Navigate = useNavigate()

    const columns = [
        {
            name: 'User Details',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },
        {
            name: '',
            selector: row => row.value,
            sortable: false,
            width: '250px',
        }
    ];

    const columns1 = [
        {
            name: 'Phone.No & Address',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },
        {
            name: '',
            selector: row => row.value,
            sortable: false,
            width: '250px',
        },
    ];

    const columns2 = [
        {
            name: 'KYC Status',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },
        {
            name: '',
            selector: row => row.value,
            sortable: false,
            width: '250px',
        }
    ];

    const verticalData = [
        { id: `1`, field: 'Citizenship', value: kycForm.KYC_document?.[0]?.citizenship || "NA" },
        { id: `2`, field: 'Country', value: kycForm.KYC_document?.[0]?.country || "NA" },
        { id: `3`, field: 'Are you Liable in US', value: kycForm.KYC_document?.[0]?.US_resident == true ? "YES" : "NO" },
        { id: `4`, field: 'Occupation', value: kycForm.KYC_document?.[0]?.occupation || "NA" },
        { id: `5`, field: 'Tax_ID', value: kycForm.KYC_document?.[0]?.tax_ID || "NA" },
        { id: `6`, field: 'Date of Birth', value: kycForm.birth_information?.[0]?.birthday || "NA" },
        { id: `7`, field: 'Place of Birth', value: kycForm.birth_information?.[0]?.birth_place || "NA" },
        { id: `8`, field: 'Gender', value: kycForm.birth_information?.[0]?.gender || "NA" },

    ].flat();

    const verticalData2 = [
        { id: `1`, field: 'Phone Number', value: kycForm.phone_number?.[0]?.number || "NA" },
        { id: `2`, field: 'Address Line 1', value: kycForm.current_Address?.[0]?.address_1 || "NA" },
        { id: `3`, field: 'Address Line 2', value: kycForm.current_Address?.[0]?.address_2 || "NA" },
        { id: `4`, field: 'Resident Country', value: kycForm.current_Address?.[0]?.resident_country || "NA" },
        { id: `5`, field: 'Provinces', value: kycForm.current_Address?.[0]?.state || "NA" },
        { id: `6`, field: 'City', value: kycForm.current_Address?.[0]?.city || "NA" },
        { id: `7`, field: 'Zip Code', value: kycForm.current_Address?.[0]?.zip_code || "NA" },

    ].flat();

    const verticalData3 = [
        { id: `1`, field: 'KYC Status', value: kycForm.sdk_verification?.sdkStatus || "Not Verified" },
        { id: `2`, field: 'Applicant-ID', value: kycForm.sdk_verification?.applicantID || "Not Verified" },
        { id: `3`, field: 'Applicant User ID', value: kycForm.sdk_verification?.externalUserID || "Not Verified" },

    ].flat();


    const handleDeleteClick = () => {
        setSelectedRow(kycForm._id);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            await handleKYCDelete(selectedRow);
        }
        setShowModal(false);
    };

    const handleClose = () => setShowModal(false);

    const handleKYCDelete = async () => {
        let datas = {
            FormId: selectedRow,
            type: "KycDelete"
        }
        let params = {
            url: `formsDelete`,
            method: 'POST',
            data: datas,

        };
        console.log('params', params)
        await makeApiRequest(params)
            .then((response) => {
                console.log('response', response)
                toast.success(response.message);
                setTimeout(() => {
                    Navigate("/dashboard/user-list")
                }, 2000)
            })
            .catch((error) => {
                console.log('error', error);
            })
    };


    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div className='App custom-individual'>
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this KYC Data?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <Dashboard />
                            </div>
                            <div className='col-lg-8 m-0 p-0'>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <div>
                                        <a href='/dashboard/user-list'>
                                            <p className="back-button" style={{ color: "#1eb7ff" }}><FaRegArrowAltCircleLeft /> Back to User List</p>
                                        </a>
                                    </div>
                                    <div>
                                        <button style={{margin:"0px -45px"}} className="custom-nav-button-1 active" onClick={(row) => { handleDeleteClick(row) }}>Delete <RiDeleteBin6Line /></button>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-9 m-sm-auto m-0 p-0'>
                                <div className='liquidity-table-1 first-table-kyc'>
                                    <DataTable
                                        columns={columns}
                                        data={verticalData}
                                        noHeader
                                        highlightOnHover
                                        dense
                                    />
                                </div>
                                <div className='liquidity-table-1 mt-3 second-table-kyc'>
                                    <DataTable
                                        columns={columns1}
                                        data={verticalData2}
                                        noHeader
                                        highlightOnHover
                                        dense
                                    />
                                </div>
                                <div className='liquidity-table-1 mt-3 third-table-kyc'>
                                    <DataTable
                                        columns={columns2}
                                        data={verticalData3}
                                        noHeader
                                        highlightOnHover
                                        dense
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {isVisible && (
                            <button
                                onClick={scrollToTop}
                                style={{
                                    position: "fixed",
                                    bottom: "70px",
                                    right: "50px",
                                    padding: "5px 16px",
                                    fontSize: "25px",
                                    backgroundColor: "#1eb7ff",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            >
                                ↑
                            </button>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default KYCform;

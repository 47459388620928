import React, { useState, useEffect } from "react";
import Dashboard from './Dashboard';
import DataTable from 'react-data-table-component';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../axiosService/ApiCall"
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function Business() {

    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const location = useLocation();
    const { businessFormData } = location.state || [];
    const Navigate = useNavigate();

    const columns = [

        {
            name: 'legal entity',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },

        {
            name: '',
            selector: row => row.value,
            sortable: false,
            width: '200px',
        }

    ];

    const columns1 = [

        {
            name: 'General AML Policies, Practices and Procedures',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },

        {
            name: 'Yes/No/N/A',
            selector: row => row.value,
            sortable: false,
            width: '200px',
        }

    ];

    const columns2 = [

        {
            name: 'PROTECTION OF PERSONAL DATA',
            selector: row => row.field,
            sortable: false,
            width: '720px',
        },



    ];

    const columns3 = [

        {
            name: 'FOREIGN ACCOUNT TAX COMPLIANCE ACT (FATCA)',
            selector: row => row.field,
            sortable: false,
            width: '720px',
        },



    ];

    const columns4 = [

        {
            name: 'REQUESTED DOCUMENTATION',
            selector: row => row.field,
            sortable: false,
            width: '500px',
        },

        {
            name: 'DOCUMENT LINK',
            selector: row => {
                if (typeof row.value === 'string') {
                    if (row.value.endsWith('.jpg') || row.value.endsWith('.png')) {
                        return <img src={row.value} alt={row.field} style={{ width: '100px', height: 'auto' }} />;
                    }
                    if (row.value.endsWith('.pdf')) {
                        return (
                            <a href={row.value} target="_blank" rel="noopener noreferrer">
                                View PDF
                            </a>
                        );
                    }
                }
                return row.value || "";
            },
            sortable: false,
            width: '200px',
        }
    ];


    const data = [
        {
            id: 1,
            name: "",
            tvl: <div>$7.01</div>,
            button1: "",
            button2: "",
            button3: <div></div>,

        }

    ];


    // Transform the data to vertical format
    const verticalData = [
        { id: `1`, field: 'Name of the Company:' },
        { id: `2`, field: 'First name ', value: businessFormData.firstname },
        { id: `3`, field: 'Abbreviate name', value: businessFormData.abbreviatename },
        { id: `4`, field: 'Foreign languages name (if applicable)', value: businessFormData.foreignlanguagesname || "--" },
        { id: `6`, field: 'State registration information' },
        { id: `7`, field: 'Data', value: businessFormData.Date },
        { id: `8`, field: 'Registration number', value: businessFormData.Registration_number },
        { id: `9`, field: 'Name of the registration authority', value: businessFormData.Registration_authority },
        { id: `10`, field: 'Place of registration', value: businessFormData.placeofregistration },
        { id: `11`, field: 'Legal address', value: businessFormData.legal_address },
        { id: `12`, field: 'Postal address', value: businessFormData.postal_address },
        { id: `13`, field: 'E-mail, URL', value: businessFormData.emailURL },
        { id: `15`, field: 'License information (if applicable)' },
        { id: `16`, field: 'Type of activity', value: businessFormData?.licensein_formation?.typeof_Activity || "--" },
        { id: `17`, field: 'License No', value: businessFormData?.licensein_formation?.licenseNo || "--" },
        { id: `18`, field: 'Date of issue', value: businessFormData?.licensein_formation?.dateofIssue || "--" },
        { id: `19`, field: 'Issuing authority', value: businessFormData?.licensein_formation?.issuing_Authority || "--" },
        { id: `20`, field: 'Validity (date of issue and date of expiration)', value: businessFormData?.licensein_formation?.validity || "--" },
        { id: `21`, field: 'Country of Tax Residency', value: businessFormData?.licensein_formation?.country_taxresidency || "--" },
        { id: `22`, field: 'Country of Tax Residency', value: businessFormData?.licensein_formation?.taxnumber || "--" },
        { id: `23`, field: 'Share capital' },
        { id: `24`, field: 'Amount of the registered share capital', value: businessFormData?.sharecaptial?.registered_sharecapital },
        { id: `25`, field: 'Amount of the paid share capital', value: businessFormData?.sharecaptial?.paid_sharecaptial },
        { id: `26`, field: 'Actual number of personnel', value: businessFormData?.sharecaptial?.numberofpersonnel },
        { id: `27`, field: 'Are there any insolvency (bankruptcy) legal proceedings  against the Company? Are there any legally effective court decision on insolvency (bankruptcy) in respect of the Company?', value: businessFormData?.sharecaptial?.legalproceedingsCompany?.bankruptcy ? 'Yes' : 'No' },
        { id: `28`, field: 'If Yes, please specify :', value: businessFormData?.sharecaptial?.legalproceedingsCompany?.bankruptcyspecify || "--" },
        { id: `29`, field: 'Are there any fact of your financial obligations default due to lack of funds in bank accounts?', value: businessFormData?.sharecaptial?.financialobligations?.lackoffunds_bankaccounts ? 'Yes' : 'No' },
        { id: `30`, field: 'If Yes, please specify :', value: businessFormData?.sharecaptial?.financialobligations?.financialspecify || "--" },
        { id: `31`, field: 'Administrative bodies of the Company (list of the Board of Directors (if applicable), CEO or representative)' },
        { id: `32`, field: '1(a). Is the Director First nominee?', value: businessFormData?.listoftheBoardofDirectors?.DirectorFirstnominee || "--" },
        { id: `33`, field: '1(b). Is the Director acting as a nominee?', value: businessFormData?.listoftheBoardofDirectors?.Directoractingnominee1 ? 'Yes' : 'No' },
        { id: `34`, field: '2(a). Is the Director Second nominee?', value: businessFormData?.listoftheBoardofDirectors?.DirectorSecondnominee || "--" },
        { id: `35`, field: '2(b). Is the Director acting as a nominee?', value: businessFormData?.listoftheBoardofDirectors?.Directoractingnominee2 ? 'Yes' : 'No' },
        { id: `36`, field: '3(a). Is the Director Third nominee?', value: businessFormData?.listoftheBoardofDirectors?.DirectorThirdnominee || "--" },
        { id: `37`, field: '3(b). Is the Director acting as a nominee?', value: businessFormData?.listoftheBoardofDirectors?.Directoractingnominee3 ? 'Yes' : 'No' },
        { id: `38`, field: '4(a). Is the Director Fouth nominee?', value: businessFormData?.listoftheBoardofDirectors?.DirectorFourthnominee || "--" },
        { id: `39`, field: '4(b). Is  the Director acting as a nominee?', value: businessFormData?.listoftheBoardofDirectors?.Directoractingnominee4 ? 'Yes' : 'No' },
        { id: `40`, field: '5(a). Is the Director Fouth nominee?', value: businessFormData?.listoftheBoardofDirectors?.DirectorFifthnominee || "--" },
        { id: `41`, field: '5(b). Is the Director acting as a nominee?', value: businessFormData?.listoftheBoardofDirectors?.Directoractingnominee5 ? 'Yes' : 'No' },
        { id: `42`, field: 'Name of the CFO (or equivalent)', value: businessFormData.NameoftheCFO },
        { id: `43`, field: 'Information about the person (s), who is the Company’s ultimate beneficial owner (s), with an indication of the share of equity (full details of the beneficial owner specified in the application in the form prescribed in Appendix 3)', value: businessFormData.details_Beneficialowner },
        { id: `44`, field: 'Details of Shareholders (participants) holding 25% or more of the authorized capital with an indication of percentage of shares held' },
        { id: `45`, field: '1(a). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.firstshareholder || "--" },
        { id: `46`, field: '1(b). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.percentageofsharesheld1 || "--" },
        { id: `47`, field: '1(c). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.Shareholdernomuinee1 ? 'Yes' : 'No' },
        { id: `48`, field: '2(a). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.secondshareholder || "--" },
        { id: `49`, field: '2(b). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.percentageofsharesheld2 || "--" },
        { id: `50`, field: '2(c). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.Shareholdernomuinee2 ? 'Yes' : 'No' },
        { id: `51`, field: '3(a). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.thirdshareholder || "--" },
        { id: `52`, field: '3(b). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.percentageofsharesheld3 || "--" },
        { id: `53`, field: '3(c). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.Shareholdernomuinee3 ? 'Yes' : 'No' },
        { id: `54`, field: '4(a). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.fourthshareholder || "--" },
        { id: `55`, field: '4(b). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.percentageofsharesheld4 || "--" },
        { id: `56`, field: '4(c). Percentage of shares held Is the Shareholder acting as a nominee?', value: businessFormData?.detailsofShareholders?.Shareholdernomuinee4 ? 'Yes' : 'No' },
        { id: `57`, field: 'Types of activity (goods, services)', value: businessFormData.Types_activity || "--" },
        { id: `58`, field: 'Region (countries) of the business activity?', value: businessFormData.Business_activity || "--" },
        { id: `59`, field: 'Does your Company have an obligation for the preparation of financial statement  to comply with applicable legislation framework?', value: businessFormData?.financial_legislation?.legislation_framework ? 'Yes' : 'No' },
        { id: `60`, field: 'If No, please specify the reason:?', value: businessFormData?.financial_legislation?.legislationspecify ? 'Yes' : 'No' },
        { id: `61`, field: 'How long the Company carries on business activity in this area?', value: businessFormData.business_activityarea || "--" },
        { id: `62`, field: 'Please specify the planning turnover of the Company (monthly/yearly), average amount of transactions', value: businessFormData.averageamount_Transactions || "--" },
        { id: `63`, field: 'Please specify the WEB site of the Company or other sources of information about the Company', value: businessFormData.website_Company || "--" },
        { id: `64`, field: 'History, reputation, segment of the market and competitors of the Company', value: businessFormData.competitors_Company || "--" },


    ].flat(); // Flatten into a single array

    // Transform the data to vertical format
    const verticalData1 = [
        { id: `1`, field: '1(a). Is your institution subject to the supervision of any regulatory authority?', value: businessFormData?.generalAMLpolicies?.Amlpolicies1?.regulatory_authority },
        { id: `2`, field: '1(b). If yes, please provide the name of the supervisory/regulatory authority, including the one responsible for supervising and controlling money laundering prevention:', value: businessFormData?.generalAMLpolicies?.Amlpolicies1?.moneylaundering_prevention || "--" },
        { id: `3`, field: '1(c). Please provide your registration / operating license number', value: businessFormData?.generalAMLpolicies?.Amlpolicies1?.operating_licensenumber || "--" },
        { id: `4`, field: '2. Does your country adhere to the 40+9 anti-money laundering and counter terrorism financing recommendations developed by the Financial Action Task Force (FATF)?', value: businessFormData?.generalAMLpolicies?.Amlpolicies2 },
        { id: `5`, field: '3(a). Does your institution have a designated Compliance Officer responsible for the overall AML/CTF program? Please provide AML/CTF program.', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.AML_CTFprogram1 },
        { id: `6`, field: '3(b).If “Yes,” please provide: ' },
        { id: `7`, field: 'Complete Name:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram2.CompleteName1 || "--" },
        { id: `8`, field: 'Position Title:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram2?.Position_Title1 || "--" },
        { id: `9`, field: 'Mailing Address:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram2?.Mailing_Address1 || "--" },
        { id: `10`, field: 'Telephone Number:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram2?.Telephone_Number1 || "--" },
        { id: `11`, field: '3(c). Does your institution have a designated employee responsible for disputes( chargebacks) and penalties?', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram3 },
        { id: `12`, field: '3(d). If “Yes,” please provide:' },
        { id: `13`, field: 'Complete Name:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram4?.CompleteName2 || "--" },
        { id: `14`, field: 'Position Title:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram4?.Position_Title2 || "--" },
        { id: `15`, field: 'Mailing Address:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram4?.Mailing_Address2 || "--" },
        { id: `16`, field: 'Telephone Number:', value: businessFormData?.generalAMLpolicies?.Amlpolicies3?.Aml_CTFprogram4?.Telephone_Number2 || "--" },
        { id: `17`, field: '4. Does your institution have a written legal and regulatory compliance program that includes a designated Compliance Officer that is responsible for coordinating and overseeing the AML program on a day to day basis?', value: businessFormData?.generalAMLpolicies?.Amlpolicies4 },
        { id: `18`, field: '5(a). Does your institution’s AML program require approval of the Board of Directors?', value: businessFormData?.generalAMLpolicies?.Amlpolicies5?.AMLprogram_Directors },
        { id: `19`, field: '5(b). Please specify the most recent date the AML program was last updated:', value: businessFormData?.generalAMLpolicies?.Amlpolicies5?.AMLprogram_DirectorsSpecify || "--" },
        { id: `20`, field: '6. Are your institution’s AML policies and procedures being applied to all your branches/subsidiaries both in the home country and in locations outside that jurisdiction?', value: businessFormData?.generalAMLpolicies?.Amlpolicies6 },
        { id: `21`, field: '7. Does your institution have written policies documenting the processes that you have in place to prevent and detect any suspicious transactions/terrorist financing activities?', value: businessFormData?.generalAMLpolicies?.Amlpolicies7 },
        { id: `22`, field: '8. Does your institution have policies and procedures to prohibit any accounts/relationships with shell banks/organizations?', value: businessFormData?.generalAMLpolicies?.Amlpolicies8 },
        { id: `23`, field: '9(a). Was your institution subject to any investigation related to ML/CTF?', value: businessFormData?.generalAMLpolicies?.Amlpolicies9?.investigation_MLandCTF },
        { id: `24`, field: '9(b). If ‘Yes’ please specify when:', value: businessFormData?.generalAMLpolicies?.Amlpolicies9?.investigation_MLandCTFSpecify || "--" },
        { id: `25`, field: '10(a). Has your institution received any regulatory enforcement action currently or within the past three years?', value: businessFormData?.generalAMLpolicies?.Amlpolicies10?.enforcement_action },
        { id: `26`, field: '10(b). If ‘Yes’, has the regulatory enforcement action resulted in fines or penalties being assessed?', value: businessFormData?.generalAMLpolicies?.Amlpolicies10?.enforcement_actionAssessed },
        { id: `27`, field: '11(a). Was your institution ever got fined for ML/CTF crimes?', value: businessFormData?.generalAMLpolicies?.Amlpolicies11?.MLandCTF_crimes || "--" },
        { id: `28`, field: '11(b). If ‘Yes’ please specify when and provide additional details regarding the fine:', value: businessFormData?.generalAMLpolicies?.Amlpolicies11?.MLandCTF_crimesSpecify || "--" },
        { id: `29`, field: '12. Does your institution have procedures in place to monitor accounts and transactions in order to prevent and detect suspicious activity?', value: businessFormData?.generalAMLpolicies?.Amlpolicies12 || "--" },
        { id: `30`, field: '13. Which categories of customers or industries according to AML/CTF program/corporate policies are restricted or prohibited by your institution? Please, specify the industries.', value: businessFormData?.generalAMLpolicies?.Amlpolicies13 || "--" },
        { id: `31`, field: 'Does your institution restrict or prohibit opening/maintaining accounts from any jurisdiction?', value: businessFormData?.generalAMLpolicies?.Amlpolicies14?.prohibit_opening },
        { id: `32`, field: 'If ‘Yes’ please specify the restricted/prohibited countries.', value: businessFormData?.generalAMLpolicies?.Amlpolicies14?.prohibit_openingSpecify || "--" },




    ].flat(); // Flatten into a single array

    // Transform the data to vertical format
    const verticalData2 = data.map(row => [
        { id: `${row.id}-1`, field: 'REM INTERNATIONAL PAYMENTS INC. may outsource the processing and storage of this data. You expressly authorize REM INTERNATIONAL PAYMENTS INC. to collect and process  this personal data. You may make a written request to consult the data concerning yourself and to rectify any inaccuracies in this data. REM INTERNATIONAL PAYMENTS INC. may record or process your personal data for the purpose of managing its contractual relationships with you, including transfer of personal data to our partners in order to provide services to you.', value: row.name },
    ]).flat(); // Flatten into a single array

    // Transform the data to vertical format
    const verticalData3 = data.map(row => [
        { id: `${row.id}-1`, field: 'We would like to draw your attention to the USA s Foreign Account Tax Compliance Act a.k.a FATCA and its possible implications*. Please indicate if you or any of your beneficial owners are a U.S citizen or resident or are otherwise to be considered as a US Person as per FATCA', value: row.name },
        { id: `${row.id}-2`, field: ' NO. I/We hereby declare that we are familiar with the FATCA guidelines and possible implications on the parties and that I am/we and any of our beneficial owners are NOT a U.S citizen or resident or are otherwise to be considered as a US Person as per FATCA', value: row.button3 },
        { id: `${row.id}-3`, field: 'YES. I/We hereby declare that we are familiar with the FATCA guidelines and possible implications on the parties and that I am/we or any of our beneficial owners ARE a U.S citizen or resident or are otherwise to be considered as a US Person as per FATCA and will provide us as soon as possible with the relevant documents, information, W-9 forms and other materials as requested by us.', value: row.button3 },
        { id: `${row.id}-4`, field: 'I/We agree to indemnify REM INTERNATIONAL PAYMENTS INC. in respect of any false or misleading information regarding my/our “U.S. person” status for U.S. federal income tax purposes. I/We agree to notify REM INTERNATIONAL PAYMENTS INC. 30 days of any change in the aforementioned statement.', value: row.button3 },
        { id: `${row.id}-5`, field: 'For more information see also: http://www.irs.gov/Businesses/Corporations/Foreign-Account-Tax-Compliance-Act-FATCA.', value: row.button3 },

    ]).flat(); // Flatten into a single array


    // Transform the data to vertical format
    const verticalData4 = [

        { id: `1`, field: 'The following documents (certified copies) shall be attached to the Questionnaire:' },
        { id: `2`, field: '1. Memorandum and Articles of Association;', value: businessFormData?.Documentation[0]?.Memorandum_document },
        { id: `3`, field: '2. Certificate of Incorporation / Registration;', value: businessFormData?.Documentation[0]?.Incorporation_document },
        { id: `4`, field: '3. Certificate of Directors/;', value: businessFormData?.Documentation[0]?.Directors_document },
        { id: `5`, field: '4. Certificate of good standing (in the event that the company is older than one year old);', value: businessFormData?.Documentation[0]?.goodstanding_document },
        { id: `6`, field: '5. Certificate of Incumbency;', value: businessFormData?.Documentation[0]?.Incumbency_document },
        { id: `7`, field: '6. Certificate of Shareholders, Register of shareholders or other equivalent document;', value: businessFormData?.Documentation[0]?.Shareholders_document },
        { id: `8`, field: '7. Legal ownership structure certified by the UBO or the person who effectively controls the company', value: businessFormData?.Documentation[0]?.Legalownership_document },
        { id: `9`, field: '8. Operating license and\or authorization;', value: businessFormData?.Documentation[0]?.Operatinglicense_document },
        { id: `10`, field: '9. Declaration of Trust (if applicable)', value: businessFormData?.Documentation[0]?.DeclarationofTrust_document || "--" },
        { id: `11`, field: '10. Trust Deeds/ Trust Settlement Agreement (if applicable)', value: businessFormData?.Documentation[0]?.TrustSettlement_document || "--" },
        { id: `12`, field: '11. Onboarding Form followed by you duly completed', value: businessFormData?.Documentation[0]?.OnboardingForm_document },
        { id: `13`, field: '12. Annual financial (accounting) statements;', value: businessFormData?.Documentation[0]?.Annualfinancial_document },
        { id: `14`, field: '13. Audited financial (accounting) statements for the last accounting period;', value: businessFormData?.Documentation[0]?.Auditedfinancial_document },
        { id: `15`, field: '14. In the absence of the documents referred to in paragraphs 12-13, an official letter, containing the reasons for the absence of these documents, shall be submitted;', value: businessFormData?.Documentation[0]?.absenceof_document },
        { id: `16`, field: '15. Identity Card or Passport of representative/s (director/s) and Utility Bill of representative/s (director/s);', value: businessFormData?.Documentation[0]?.IdentityCardorPassport_document },
        { id: `17`, field: '16. Details of the Company’s UBO’s including certified copy of passport, and proof of permanent address.', value: businessFormData?.Documentation[0]?.permanentaddress_document },
        { id: `18`, field: '17. Account opening confirmation', value: businessFormData?.Documentation[0]?.Accountopening_document },
        { id: `19`, field: '18. AML/CTF program (if applicable)', value: businessFormData?.Documentation[0]?.AML_CTF_document || "--" },
        { id: `20`, field: '*All documents shall be less than 6 months old from the date this questionnaire is signed,  presented in hard copies duly certified before any operational activities.' },
        { id: `21`, field: 'Name, Director/Representative Signature', value: businessFormData?.Documentation[0]?.signature },
    ].flat(); // Flatten into a single array


    const handleDeleteClick = () => {
        setSelectedRow(businessFormData._id);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            await handleKYCDelete(selectedRow);
        }
        setShowModal(false);
    };

    const handleClose = () => setShowModal(false);

    const handleKYCDelete = async () => {
        let datas = {
            FormId: selectedRow,
            type: "BusinessDelete"
        }
        let params = {
            url: `formsDelete`,
            method: 'POST',
            data: datas,

        };
        console.log('params', params)
        await makeApiRequest(params)
            .then((response) => {
                console.log('response', response)
                toast.success(response.message);
                setTimeout(() => {
                    Navigate("/dashboard/user-list")
                }, 2000)
            })
            .catch((error) => {
                console.log('error', error);
            })
    };




    const [isVisible, setIsVisible] = useState(false);

    // Show button when the user scrolls down 300px
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the page to the top when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling effect
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);


    return (
        <div className='App custom-bussiness'>
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this KYC Data?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <Dashboard />
                            </div>
                            <div className='col-lg-8 me-sm-auto m-0 p-0'>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <a href='/dashboard/user-list'>
                                            <p className="back-button" style={{ color: "#1eb7ff" }}><FaRegArrowAltCircleLeft /> Back to User List</p>
                                        </a>
                                    </div>

                                    <div>
                                        <button className="custom-nav-button-1 active" onClick={handleDeleteClick}>Delete <RiDeleteBin6Line /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 m-sm-auto m-0 p-0'>

                                <div className='liquidity-table-1 first-table-bus'>
                                    <DataTable
                                        columns={columns}
                                        data={verticalData}
                                        noHeader

                                        highlightOnHover
                                        dense

                                    />
                                </div>

                                <div className='liquidity-table-1 mt-5  first-second-bus'>
                                    <DataTable
                                        columns={columns1}
                                        data={verticalData1}
                                        noHeader

                                        highlightOnHover
                                        dense

                                    />
                                </div>

                                <div className='liquidity-table-1 mt-5'>
                                    <DataTable
                                        columns={columns2}
                                        data={verticalData2}
                                        noHeader

                                        highlightOnHover
                                        dense

                                    />
                                </div>


                                <div className='liquidity-table-1 mt-5'>
                                    <DataTable
                                        columns={columns3}
                                        data={verticalData3}
                                        noHeader

                                        highlightOnHover
                                        dense

                                    />
                                </div>

                                <div className='liquidity-table-1 mt-5 five-table-bus'>
                                    <DataTable
                                        columns={columns4}
                                        data={verticalData4}
                                        noHeader

                                        highlightOnHover
                                        dense

                                    />
                                </div>


                            </div>
                        </div>
                        <div>
                            {isVisible && (
                                <button
                                    onClick={scrollToTop}
                                    style={{
                                        position: "fixed",
                                        bottom: "70px",
                                        right: "50px",
                                        padding: "5px 16px",
                                        fontSize: "25px",
                                        backgroundColor: "#1eb7ff",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                    }}
                                >
                                    ↑
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Business
import React , { useState } from 'react'
import '../Assets/css/navbar.css'
import { IoMdLogOut } from 'react-icons/io'
import { Link, useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function Navbar() {
    // const location = useLocation();
    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
      
        localStorage.clear();
        
        window.location.href = '/'; 
    };

    return (
        <div className='App'>
            <div className='container-fluid'>
                <nav class="navbar navbar-expand-lg bg-body-tertiary custom-nav-top-1">
                    <div class="container-fluid">
                        <a class="navbar-brand custom-right-nav-name-1" href="/">
                            <img src="https://res.cloudinary.com/dqxmwxnkl/image/upload/v1726124644/Logo_rempic_swan_ue7lhi_snagd5.png" />

                        </a>
                        <button class="navbar-toggler navbar-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                            <Link className="nav-link" to="#" onClick={() => setShowModal(true)}>
                                <button className="custom-nav-button-1 active" alt="Log Out">
                                    <IoMdLogOut className="sidenav-icon-size-css" /> Log Out
                                </button>
                            </Link>
                            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm Logout</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to log out?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={handleLogout}>
                                        Yes, Log Out
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* <ul class="navbar-nav">
                                <li class="nav-item dashboard-hide">
                                    <a class="nav-link" aria-current="page" href="/login">
                                        <button className='custom-nav-button-1'>Log in</button>
                                    </a>
                                </li>
                                <li class="nav-item dashboard-hide">
                                    <a class="nav-link active" href="#">
                                        <button className='custom-nav-button-1 active' >Sign up</button>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar
let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    adminBackendUrl: "http://localhost:4000/admin/",
  };
} else {
  config = {
    adminBackendUrl: "https://api.rempic.com/admin/",
  };
}

export default config;